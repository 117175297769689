export const INFO = {
    pic: 'MohamedWafy.jpg',
    about: '昨天是段历史，明天是个谜团，而今天是天赐的礼物，要像珍惜礼物那样珍惜今天，从始至终我们都会回到时间的问题上.'
}


export const LINKS = [
    { title: 'Facebook', href: 'https://www.facebook.com/100087409027814', brd: '#1e90ff', sdw: '#1e90ff22' },
    { title: 'Twitter X', href: 'https://twitter.com/NiceDayTooYou', brd: '#333', sdw: '#3332' },
    { title: 'instagram', href: 'https://www.instagram.com/serendipityniceday', brd: '#F4B400', sdw: '#F4B40022' },
    { title: 'YouTube', href: 'https://youtube.com/@serendipityniceday', brd: '#C71610', sdw: '#C7161022' },
    { title: 'Email', href: 'mailto: nicedaytoooyou@gmail.com', brd: '#000', sdw: '#0002' },
]


export const SKILLS = [
    'Broccoli', 'Potato', 'Tomato', 'Spinach', 'Cucumber', 'Pickle', 'durian', 'watermelon',
    'chili', 'bamboo shoots', 'lettuce', 'pumpkin', 'pizza', 'pasta', 'Cheese', 'banana',
    'blueberry', 'love story'
]


export const PROJECTS = [
    { tag: '1', title: '安居乐业', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '2', title: '守株待兔', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '3', title: '三思而行', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '5', title: '百年好合', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '6', title: '井底之蛙', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '7', title: '世外桃源', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '8', title: '明察秋毫', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '9', title: '知己知彼', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '10', title: '天长地久', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '11', title: '一帆风顺', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '12', title: '落叶归根', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '13', title: '循规蹈矩', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
    { tag: '15', title: '画龙点睛', img: 'https://s1.imagehub.cc/images/2024/05/20/a4cf1c253ef5adfcb07c70ec8a4b433b.png', link: 'https://www.liyong.online', },
]


export const CERTIFICATES = [
    { tag: '- 北京奥运会闭幕式演讲家罗马诺·普罗迪', title: '行动是成功的阶梯。', img: 'https://s1.imagehub.cc/images/2024/05/20/2f29ae5f82254fd8afd43987b14c5e40.png', link: 'https://see.liyong.online', },
    { tag: '- 未来学家杰克·霍兰德', title: '每天都是一张白纸，你可以写下你想要的故事。', img: "https://s1.imagehub.cc/images/2024/05/20/97e824c6c76025a54817b3c4b01f0984.png", link: 'https://past.liyong.online', },
    { tag: '- 作家埃琳诺·罗斯福', title: '如果你能梦想到它，你就能做到它。', img: 'https://s1.imagehub.cc/images/2024/05/20/2f29ae5f82254fd8afd43987b14c5e40.png', link: 'https://www.liyong.online', },
    { tag: '- 作家罗伯特·科尔里', title: '成功是一连串小小的努力，一天天持之以恒的工作。', img: 'https://s1.imagehub.cc/images/2024/05/20/97e824c6c76025a54817b3c4b01f0984.png', link: 'https://winter.liyong.online', },
    { tag: '- 作家贾科莫·卡萨诺瓦', title: '智慧是不断学习，勇气是不断前行。', img: 'https://s1.imagehub.cc/images/2024/05/20/2f29ae5f82254fd8afd43987b14c5e40.png', link: 'https://home.liyong.online', },
    { tag: '- 作家威廉·亨利·塞尔', title: '决心比天赋重要。', img: 'https://s1.imagehub.cc/images/2024/05/20/97e824c6c76025a54817b3c4b01f0984.png', link: 'https://one.liyong.online', },
    { tag: '- 作家本杰明·弗兰克林', title: '幸福不是事情的结果，而是一种生活方式。', img: 'https://s1.imagehub.cc/images/2024/05/20/2f29ae5f82254fd8afd43987b14c5e40.png', link: 'https://liyong.online', },
]
